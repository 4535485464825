:root {
  --primaryRed: #da291c;
  --darkRed: #C42115;
  --lightGray: #f7f5f2;
  --darkGray: #D3D3D3;
  --pink: #EA766D;
  --white: #FFFFFF;
  --primaryGray: #C4C4C4;
  --primaryDarkGray: #7B7B7B;
  --borderColor: #E3E3E3;
  --primaryText: #101820;
  --placeholderColor: #65676A;
  --menuGray: #ECEFF2;
  --orange: #ED8B00;

  --verRedDark: #F59911;
  --redDark: #a61315;
  --red: #DA291C;
  --red2: #C42115;
  --red3: #B92318;
  --red4: #A43339;
  --red5: #A8353A;
  --redLight: #e25f5a;
  --redLight2: #EA766D;
  --darkerOrange: #d57d00;
  --lightOrange: #FEDBB7;
  --offWhite: #F7F5F2;
  --whiteTransparent: #FFFFFFAA;
  --offBlack: #101820;
  --offerNot: #757575;
  --disabledBG: #b7b7b7;
  --disabledText: #686868;
  --borderColorLight: #EDEDED;
  --focusedBorderColor: #ED8B00;
  --readOnlyColor: #65676A;
  --anotherGrayColor: #65676A;
  --playOrParkQRBG: #f9f7f3;
  --playOrParkDarkBlack: #231F20;
  --someLightGray: #ccccca;

  --gothamBold: 'Gotham Bold';
  --gothamMedium: 'Gotham Medium';
  --gothamBook: 'Gotham Book';
  --gotham: 'Gotham Book';
  --gothamBlack: 'Gotham Black';

  --shadowPanel: 0px 3px 30px #10182026;
  --boxShadow: 0px 5px 8px #10182026;

  --padding-default: 16px;
  --padding-minimal: 16px;

  --borderWidth: 1px;
  --sliderIndicator: 8px;
  
  --font-px9: 9px;
  --font-px10: 10px;
  --font-px11: 11px;
  --font-tiny: 12px;
  --font-px13: 13px;
  --font-default: 14px;
  --font-larger: 15px;
  --font-px16: 16px;
  --font-px18: 18px;
  --font-px20: 20px;
  --font-px22: 22px;
  --font-px24: 24px;
  --font-px30: 30px;
  --font-px32: 32px;
  --font-px36: 36px;
  --font-px40: 40px;

  --defaultIconWidth: 40px;
  --defaultIconHeight: 40px;
  --inputHeight: 45px;
  --readonlyInputHeight: 35px;
  --smallIconHeight: 24px;
  --smallIconWidth: 24px;
  --headerLogo: 30px;
  --radius: 8px;
}




body {
  margin: 0;
  font-family: "Gotham Book", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primaryText);
}

p {
  line-height: 1.25;
}

@font-face {
  font-family: "Gotham Bold";
  src: url('./fonts/gotham-bold-webfont.woff2') format('woff2'),
    url('./fonts/gotham-bold-webfont.woff') format('woff'),
    url("./fonts/Gotham-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Medium";
  src: url('./fonts/gotham-medium-webfont.woff2') format('woff2'),
    url('./fonts/gotham-medium-webfont.woff') format('woff'),
    url("./fonts/Gotham-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Book";
  src: url('./fonts/gotham-book-webfont.woff2') format('woff2'),
    url('./fonts/gotham-book-webfont.woff') format('woff'),
    url("./fonts/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Black";
  src: url('./fonts/gotham-black-webfont.woff2') format('woff2'),
    url('./fonts/gotham-black-webfont.woff') format('woff'),
    url("./fonts/Gotham-Black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.desktop-logo, .mobile-logo {
  display: none;
}

.confirmation-holder {
  position: fixed;
  inset: 0px;
  display: flex;
  place-content: center;
  align-items: center;
}

.confirmation-holder-inner {
  max-width: 688px;
  z-index: 1;
  padding: 50px 66px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(16, 24, 32, 0.15) 0px 3px 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media all and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }

  .main {
    min-height: 850px;
  
    background: rgb(255, 255, 255);
    box-shadow: rgba(16, 24, 32, 0.15) 0px 3px 30px;
    max-width: 768px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    display: inline-block;
  }

  .desktop-logo {
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .logged-in-sidebar {
    width: 80px;
    border-top-left-radius: 20px;
    text-align: center;
    border-right: 1px solid rgb(227, 227, 227);
    box-sizing: border-box;
    min-height: 850px;
  }

  .logged-in-sidebar button.ck-button-custom {
    width: 100%;
    padding: 5px 0px;
  }
  
  .logged-in-sidebar button.ck-button-custom:hover {
    padding: 5px;
    cursor: pointer;
  }
  
  .logged-in-sidebar button.ck-button-custom .ck-side-button-special {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
  }
  
  .logged-in-sidebar button.ck-button-custom:hover .ck-side-button-special {
    background-color: var(--menuGray);
  }
  
  .logged-in-sidebar button.ck-button-custom .ck-side-button-deco {
    width: 2px;
    height: 32px;
    top: 9px;
    background-color: var(--primaryRed);
    position: absolute;
    left: 0;
  }
  
  .logged-in-sidebar button.ck-button-custom:hover .ck-side-button-deco {
    left: -5px;
  }

  .ck-side-buton-special-main .ck-side-button-special-label {
    border-radius: 5px;
    width: 100%;
    background-color: transparent;
    font-family: "Gotham Book";
    line-height: 1.25;
    font-weight: normal;
    color: var(--white);
    margin-top: 5px;
    font-size: 14px;
    height: 26px;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
  }
  
  .ck-side-buton-special-main:hover .ck-side-button-special-label {
    background-color: #10182099;
  }

  .main-container-how-extra {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.logged-in-header {
  height: 44px;
  display: flex;
  place-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(227, 227, 227);
  position: relative;
  box-sizing: border-box;
}

.logged-in-header .header-title {
  font-family: "Gotham Medium";
  line-height: 16px;
  font-weight: normal;
  font-size: 16px;
  flex: 1 1 0%;
}

.logged-in-header .right-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
}

.screen-loading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  height: 100%;
  position: relative
}

.screen-loading-holder .loading-holder {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}

.dropdown-menu:not(.show) {
  display: none;
}
.dropdown-menu {
  z-index: 999
}

.terms a {
  color: var(--orange);
}
.terms div {
 font-size: 15px;
 line-height: 22.5px;
 font-family: var(--gothamBook);
 font-weight: normal;
 color: #101820;
}

.terms strong, .terms ul, .terms li {
 font-size: var(--font-larger);
 line-height: 1.5;
 font-family: var(--gothamBook);
 font-weight: normal;
 color: var(--primaryText);
}

.terms h1 {
  font-family: var(--gothamBlack);
  font-weight: normal;
  font-size: var(--font-px30);
  line-height: 1.5;
  min-height: 45px;
  margin-bottom: var(--padding-default);
  color: var(--primaryText);
}

.terms h2 {
  font-family: var(--gothamBlack);
  font-weight: normal;
  font-size: var(--font-px18);
  line-height: 1.5;
  min-height: 27px;
  color: var(--primaryText);
  margin-bottom: -20px;
}

div.ck-modal {
    box-shadow: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.shadow-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-right: 15px;
  margin-left: 15px;
  box-sizing: border-box;
}

.shadow-panel .shadow-panel-container {
  background: var(--white);
  --mox-box-shadow: var(--shadowPanel);
  --webkit-box-shadow: var(--shadowPanel);
  box-shadow: var(--shadowPanel);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding-bottom: 40px;
  box-sizing: border-box;
  max-width: 540px;
}

.shadow-panel .shadow-panel-container .logo-container {
  background-color: var(--lightGray);
  width: 100%;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom-color: var(--darkGray);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.shadow-panel .shadow-panel-container .logo-container svg {
  width: 238px;
  height: 38px;
}

.shadow-panel .shadow-panel-container .logo-pro-container {
  background-color: var(--lightGray);
  width: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 20px;
}

.shadow-panel .shadow-panel-container .logo-pro-container svg {
  width: 145px;
  height: 26px;
}

.shadow-panel .shadow-panel-container .children {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 34px;
  width: 100%;
  box-sizing: border-box;
}

.ipnut-field {
  height: 46px;
  font-family: var(--gothamBook);
  font-weight: normal;
  background-color: var(--white);
  font-size: var(--font-larger);
  border-width: 1px;
  border-color: var(--borderColor);
  border-style: solid;
  width: 100%;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  outline: none;
}

.header-h1 {
  color: var(--primaryText);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--gothamBold);
  font-weight: normal;
}

.ck-button-custom {
  box-sizing: border-box;
  position: relative;
  border: none;
  background-color: transparent;
}

.ck-button {
  box-sizing: border-box;
  position: relative;
  font-family: var(--gothamBold);
  font-weight: normal;
  font-size: var(--font-larger);
  height: 46px;
  border-style: solid;
  width: 100%;
}

.ck-button-link {
  box-sizing: border-box;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: var(--font-px16);
}

.ck-button-right-save {
  font-family: "Gotham Book";
  line-height: 16px;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
}

.ck-separator {
  background-color: var(--darkGray);
  height: 1px;
  margin-top: 40px;
  margin-bottom: 30px;
  width: 86%;
  margin-left: 7%;
}
.ck-separator.full {
  width: 100%;
  margin-left: 0;
}
.ck-separator.smaller {
  width: 60%;
  margin-left: 20%;
}
.ck-separator.nospace {
  margin-top: 0;
  margin-bottom: 0;
}

.terms-popup-container {
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  inset: 0px;
  background-color: rgba(16, 24, 32, 0.56);
  box-sizing: border-box;
  padding: var(--padding-default);
}

.terms-popup-container .content {
  z-index: 1;
  background-color: var(--white);
  border-radius: 16px;
  padding: 48px;
  position: relative;
  max-width: 688px;
  width: 100%;
  max-height: 100%;
  overflow: scroll;
  box-sizing: border-box;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-container .home-top-section {
  position: relative;
  background-color: var(--primaryRed);
  border-top-right-radius: 20px;
  line-height: 1.5;
  padding-top: 25px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.home-container .home-top-section .intro {
  background-color: var(--darkRed);
  border-radius: var(--radius);
  padding: 15px;
  text-align: center;
  max-width: 328px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  color: var(--white);
  position: relative;
}

.home-container .home-top-section .intro .name {
  font-family: "Gotham Black";
  line-height: 22px;
  font-weight: normal;
  font-size: 22px;
  padding-top: 4px;
}

.home-container .home-top-section .intro .current-status-info {
  font-family: "Gotham Book";
  line-height: 12px;
  font-weight: normal;
  font-size: 12px;
  padding-top: 10px;
}

.home-container .home-top-section .intro .current-status {
  font-family: "Gotham Bold";
  line-height: 15px;
  font-weight: normal;
  font-size: 15px;
  margin-top: 8px;
}

.home-container .home-top-section .progress-holder {
  margin-top: 15px;
  background-color: var(--white);
  border-radius: var(--radius);
  max-width: 328px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  color: var(--primaryText);
}

.home-container .home-top-section .progress-holder .top-section {
  text-align: left;
  padding: 10px 15px;
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 14px;
  position: relative;
}

.home-container .home-top-section .progress-holder .middle-section {
  background-color: rgb(247, 245, 242);
  height: 95px;
  padding: 24px 16px;
  box-sizing: border-box;
}

.home-container .home-top-section .progress-holder .bottom-section {
  text-align: center;
  padding: 10px 6px;
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  font-size: 10px;
  position: relative;
}

.ck-button-view-membership {
  text-transform: uppercase;
  background-color: rgb(196, 33, 21);
  border-radius: 8px;
  height: 30px;
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  font-size: 12px;
  color: rgb(255, 255, 255);
  max-width: 328px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
}

.ck-button-view-membership:hover {
  background-color: rgb(196, 33, 21, 0.6);
}

.home-container .home-top-section .deco {
  position: absolute;
  bottom: -7px;
  height: 7px;
  line-height: 7px;
  left: calc(50% - 9px);
}

.home-container .home-top-section .deco img {
  width: 18px;
  height: 7px;
  display: flex;
}

.home-main-title {
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-offers-container {
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 328px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  padding-bottom: 48px;
}

.offer-container {
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: rgba(16, 24, 32, 0.15) 0px 5px 8px;
  margin-top: 16px;
}

.offer-container .inner {
  background-color: var(--white);
  flex: 1 1 0%;
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}

.offer-container .inner .title {
  font-family: "Gotham Black";
  text-align: left;
  line-height: 1.25;
  font-weight: normal;
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.offer-container .inner .description {
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 13px;
  color: rgb(101, 103, 106);
  margin-top: 4px;
  text-align: left;
}

.scale-down {
  transform: scale(0.6);
}

.offer-container-progress:not(.offer-containerless) {
  background-color: rgb(247, 245, 242);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100px;
  height: 100px;
  position: relative;
}

.offer-container-progress.offer-containerless {
  width: 100px;
  height: 100px;
  position: relative;
}

.offer-container-progress .inner-holder {
  position: absolute;
  inset: 10px;
  overflow: hidden;
  border-radius: 40px;
}

.offer-container-progress .inner-holder .inner-bg {
  position: absolute;
  background-color: var(--anotherGrayColor);
  inset: 0px;
}

.offer-container-progress .inner-holder .progress-separator {
  position: absolute;
  inset: 0px;
}

.offer-container-progress .inner-holder .progress-separator2 {
  background-color: var(--white);
  width: 3px;
  height: 40px;
  margin-left: 38.5px;
}

.offer-container-progress .offer-icon {
  position: absolute;
  background-color: rgb(254, 219, 183);
  width: 62px;
  height: 62px;
  border-radius: 40px;
  left: 9px;
  top: 9px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.offer-container-progress .offer-icon-bg {
  position: absolute;
  background-color: rgb(255, 255, 255);
  inset: 6px;
  border-radius: 40px;
}

.offer-details {
  display: flex;
  flex-direction: column;
}

.offer-details .offer-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.offer-details .offer-progress {
  display: flex;
  align-self: center;
  margin-top: -51px;
  margin-bottom: 16px;
}

.offer-details .offer-subtitle {
  display: flex;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 12px;
  color: rgb(237, 139, 0);
  margin-bottom: 2px;
}

.offer-details .offer-title {
  display: flex;
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 22px;
  color: rgb(16, 24, 32);
}

.offer-details .offer-description {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: rgb(16, 24, 32);
  margin-top: 3px;
  text-align: left;
}

.inner-page-container {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
}

.modal-header {
  display: flex;
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 22px;
  margin-top: 24px;
  color: rgb(16, 24, 32);
}

.modal-description {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: rgb(16, 24, 32);
}

.modal-close {
  position: absolute;
  right: 16px;
  top: 17px;
}

.modal-close:hover {
  opacity: 0.75;
}

.progress-next-level-text {
  font-size: 10px;
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  color: rgb(16, 24, 32);
  text-align: center;
}

.progress-next-level-text .special {
  color: rgb(218, 41, 28);
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
}

.progress-next-level-text-win {
  margin-right: 5.33333px;
  font-size: 10px;
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  color: rgb(237, 139, 0);
  text-align: center;
}

.view-membership-level-holder {
  display: flex;
  flex-direction: column;
  max-width: 430px;
  width: 100%;
  align-self: center;
  justify-content: center;
}

.view-membership-level-holder .image {
  width: 100%;
}

.view-membership-level-holder .content-holder {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 328px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.view-membership-level-holder .title {
  text-align: left;
  font-family: "Gotham Black";
  line-height: 33px;
  font-weight: normal;
  font-size: 22px;
  margin-top: 24px;
  color: rgb(16, 24, 32);
}

.view-membership-level-holder .description {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: rgb(16, 24, 32);
  margin-top: 3px;
}

.view-membership-level-holder .table {
  margin-top: 24px;
  flex-direction: row;
  display: flex;
}

.view-membership-level-holder .table .first-column {
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(247, 245, 242);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 1px;
  flex-direction: column;
}

.view-membership-level-holder .table .first-column .logo,
.view-membership-level-holder .table .main-cell {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(255, 255, 255);
  flex-direction: column;
}

.table .cell-container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 125px;
  padding: 16px 8px;
}

.table .cell-container .title {
  display: flex;
  text-align: center;
  font-family: "Gotham Medium";
  line-height: 13.5px;
  font-weight: normal;
  font-size: 9px;
  margin-top: 8px;
}

.table .cell-container .offer-indicator {
  display: flex;
  background-color: rgb(254, 219, 183);
  width: 45px;
  height: 45px;
  min-height: 45px;
  min-width: 45px;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.view-membership-level-holder .table .second-column,
.view-membership-level-holder .table .third-column,
.view-membership-level-holder .table .fourth-column {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background-color: rgb(237, 139, 0);
  margin-right: 1px;
}

.view-membership-level-holder .table .third-column {
  background-color: var(--red);
}

.view-membership-level-holder .table .fourth-column {
  background-color: var(--red5);
  border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.view-membership-level-holder .table .main-cell .cell-title {
  display: flex;
  font-family: "Gotham Bold";
  line-height: 1.25;
  font-weight: normal;
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.view-membership-level-holder .table .main-cell .cell-subtitle {
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  font-size: 11px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 2px;
}

.view-membership-level-holder .table .cell-text {
  text-align: center;
  font-family: "Gotham Black";
  line-height: 16px;
  font-weight: normal;
  font-size: 14px;
  margin-top: 8px;
  color: rgb(255, 255, 255);
  white-space: pre-wrap;
}

.view-membership-level-holder .table .cell-text .mini {
  font-family: "Gotham Medium";
  line-height: 1.25;
  font-weight: normal;
  font-size: 10px;
  color: rgb(255, 255, 255);
}

.profile-container {
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  text-align: left;
  max-width: 328px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.main-container {
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
}

.main-container.maxed {
  max-width: 328px;
}

.transaction-tab-button-container {
  background-color: rgb(227, 227, 227);
  display: flex;
  flex: 1 1 0%;
  margin-right: 1px;
  height: 40px;
  justify-content: center;
  border-radius: 8px 0px 0px 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.transaction-tab-button-container .text {
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  margin-top: 0px;
  align-items: center;
}

.transactions-empty-text {
  display: inline-block;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
  text-align: center;
}

.transaction-date {
  display: flex;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 12px;
  color: rgb(218, 41, 28);
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

.transaction-details {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.transaction-details .left {
  display: flex;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
  text-align: left;
  flex: 1 1 0%;
}

.transaction-details .right {
  font-family: "Gotham Bold";
  line-height: 1.25;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
}

.transaction-location {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border-top: 1px solid rgb(227, 227, 227);
  align-items: center;
  padding: 5.33333px 16px;
}

.transaction-location .text {
  display: flex;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 12px;
  color: rgb(16, 24, 32);
  text-align: left;
  flex: 1 1 0%;
  margin-left: 8px;
}

.change-pass-title {
  display: inline-block;
  width: 100%;
  font-family: "Gotham Bold";
  line-height: 1.25;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  margin-bottom: 8px;
  color: rgb(16, 24, 32);
}

.change-pass-subtitle {
  display: inline-block;
  width: 100%;
  font-family: "Gotham Book";
  line-height: 22.5px;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
  text-align: center;
  margin-bottom: 40px;
  margin-top: 16px;
}

.delete-pass-container {
  margin-bottom: 40px;
  max-width: 328px;
  width: 100%;
  align-self: center;
  text-align: left;
}

.exclusive-members-container {
  padding-top: 48px;
  max-width: 328px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.exclusive-members-container .offer {
  padding: 0px;
  display: flex;
  flex: 1 1 0%;
}

.exclusive-members-container .offer:hover {
  opacity: 0.85;
}

.exclusive-members-container .offer .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(16, 24, 32, 0.15) 0px 5px 8px;
  margin-top: 0px;
}

.exclusive-members-container .offer .container .top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  background-color: rgb(237, 139, 0);
  height: 209px;
}

.exclusive-members-container .offer .container .top img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.exclusive-members-container .offer .container .bottom {
  padding: 12.8px;
}

.exclusive-members-container .offer .container .bottom .text {
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
}

.ck-menu-panel {
  border: 1px solid var(--borderColor);
  background: var(--white);
}

.ck-menu-panel.round {
  border-radius: var(--radius);
}

.ck-menu-panel .title {
  display: flex;
  font-family: "Gotham Black";
  line-height: 1.25;
  font-weight: normal;
  font-size: 16px;
  color: rgb(16, 24, 32);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.ck-menu-panel .description {
  display: flex;
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 12px;
  padding: 5.33333px 16px 16px;
  color: rgb(16, 24, 32);
}

.inbox-container {
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
}

.inbox-container .inner-container {
  margin-top: 48px;
  margin-left: 48px;
  margin-right: 48px;
  width: 100%;
}

.inbox-container .inner-container .empty {
  font-family: "Gotham Book";
  line-height: 22.5px;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
  text-align: center;
  padding-bottom: 24px;
  padding-top: 32px;
  display: inline-block;
}

.ck-menu-item-container {
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
}

.ck-menu-item-container .inner {
  padding: 13.3333px 16px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ck-menu-item-container .inner .ck-menu-item-inner-container {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 0%;
}

.ck-menu-item-container .inner .ck-menu-item-inner-container.has-icon {
  margin-left: var(--padding-default);
}

.ck-menu-item-container .inner .ck-menu-item-inner-container .first-line {
  font-family: "Gotham Book";
  line-height: 1.25;
  font-weight: normal;
  font-size: 16px;
  color: rgb(16, 24, 32);
}

.ck-menu-item-container .inner .ck-menu-item-inner-container .second-line {
  font-family: "Gotham Book";
  line-height: 22.5px;
  font-weight: normal;
  font-size: 15px;
  color: rgb(16, 24, 32);
  text-align: left;
}

.ck-menu-item-container .inner .ck-menu-item-inner-container .bold {
  font-family: var(--gothamBold);
}
.ck-menu-item-container .inner .ck-menu-item-inner-container .medium {
  font-family: var(--gothamMedium);
}

.single-inbox-text-holder {
  display: flex;
  font-family: var(--gothamMedium);
  line-height: 1.25;
  font-weight: normal;
  font-size: 14px;
  color: var(--red);
  margin-top: 8px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--borderColor);
}

.single-inbox-text-holder-from {
  color: var(--offBlack);
}

.single-inbox-subject-holder {
  display: flex;
  font-family: var(--gothamBlack);
  line-height: 1.25;
  font-weight: normal;
  font-size: 22px;
  margin-top: 24px;
  color: var(--offBlack);
  padding-left: 48px;
  padding-right: 48px;
  text-align: left;
}

.single-inbox-subject-body {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: var(--offBlack);
  margin-top: 16px;
  padding-left: 48px;
  padding-right: 48px;
  text-align: left;
}

.single-page-subject {
  display: flex;
  text-align: left;
  font-family: var(--gothamBlack);
  line-height: 1.25;
  font-weight: normal;
  font-size: 22px;
  margin-top: 24px;
  color: var(--offBlack);
}

.single-page-body {
  display: flex;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: var(--offBlack);
  margin-top: 16px;
}

.play-button {
  position: absolute;
  inset: 0px;
  padding: 0px;
}
.play-button:hover {
  opacity: 0.75;
}

.main-app-holder {
  display: flex;
  flex-direction: row;
}


@media all and (max-width: 767px) {
  .terms-popup-container .content {
    padding: 16px;
  }

  .shadow-panel .shadow-panel-container .children {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }

  .main-app-holder {
    flex-direction: column;
  }

  .mobile-logo {
    display: inline-block;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .hide-on-mobile {
    display: none;
  }
  
  .button-holders {
    border-radius: 30px;
    background-color: var(--borderColor);
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .button-holders .ck-button-custom {
    flex: 1;
    border-radius: 30px;
    height: 45px;
  }

  .button-holders .ck-button-custom.selected {
    flex: 1;
    border-radius: 30px;
    background-color: var(--white);
    height: 45px;
    box-shadow: 0px 3px 8px #10182026;
  }

  .ck-side-button-special svg {
    height: 24px!important;
    width: 24px!important;
    margin-top: 0px!important;
  }

  .side-button-custom .ck-side-button-special-label {
    color: var(--placeholderColor);
    font-family: var(--gotham);
    font-size: 10px;
    line-height: 12px;
  }

  .selected .side-button-custom .ck-side-button-special-label {
    color: var(--red);
  }

  .home-container .home-top-section {
    border-top-right-radius: 0px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }

  .home-container .home-top-section .progress-holder .top-section {
    padding-right: 30px;
  }

  .home-offers-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  div.slide-pane__content {
    padding: 16px;
  }

  .inbox-container .inner-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 0;
  }

  .single-inbox-text-holder,
  .single-inbox-subject-holder,
  .single-inbox-subject-body,
  .main-container-how-extra,
  .main-container,
  .profile-container,
  .inner-page-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .main-container,
  .profile-container,
  .inner-page-container,
  .exclusive-members-container {
    padding-top: 0;
  }

  .ck-button-custom.ck-menu-item-container.hide-on-desktop.special-button-treatment {
    border-bottom: 1px solid var(--borderColor);
  }

  .add-top-space {
    margin-top: 16px;
  }

  .confirmation-holder {
    padding-left: 16px;
    padding-right: 16px;
  }

  .confirmation-holder-inner {
    padding: 16px;
  }

  .inbox-container .inner-container {
    max-width: 328px;
  }

  .ck-menu-panel:not(.round) {
    max-width: 328px;
    display: inline-block;
    width: 100%;
  }

  .ck-menu-item-container .inner .ck-menu-item-inner-container .medium {
    font-family: var(--gotham);
  }
}